<template>
  <div class="fill-height">
    <v-row class="fill-height ma-0" no-gutters>
      <v-col cols="4" v-if="!$vuetify.breakpoint.smAndDown" class="fill-height d-flex justify-center align-center mr-10"
             :style="{ background: $vuetify.theme.themes['light'].primary }">
        <v-img min-width="50px" contain src="@/assets/programmer-cat.jpg">
        </v-img>
      </v-col>
      <v-col class="d-flex align-center pr-16">
        <v-card flat :style="{ background: 'inherit' }">
          <v-row class="pb-8 ml-8 mr-6">
            <v-img width="350px" src="@/assets/Logo_blauw.png"/>
          </v-row>
          <v-row v-if="!$vuetify.breakpoint.smAndDown" class="ma-16"></v-row>
          <v-row class="ma-4">
            <v-card-title class="pl-0 titleCard">
              <h1>Currently down due to maintenance</h1>
            </v-card-title>
            <v-card-text class="pl-0 text-xl-body-1">
              <p>{{ timeToBeBackOnline() }}</p>
              <p>Voor dringende zaken, kan u ons contacteren via info@buildbase.be.</p>
            </v-card-text>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import {parseStringToTime} from "@/shared/utils/dateUtils";

export default {
  name: "Maintenance.vue",
  methods: {
    timeToBeBackOnline() {
      let backOnlinePrediction = process.env.VUE_APP_MAINTENANCE_ONLINE_PREDICTION;
      return backOnlinePrediction ? `We verwachten terug te zijn rond ${parseStringToTime(backOnlinePrediction)}u.`
          : "We verwachten zodra terug online te zijn."
    }
  }
}
</script>
<style scoped>
.titleCard{
  line-height: normal;
  word-break: keep-all;
}
</style>
